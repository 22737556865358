<template>
  <div class="history-urgent">
    <SearchBarUrgent />
    <div class="container-urgent">
      <RingLoading v-if="searchLoading" />
      <div ref="urgentEventCard" v-for="(event, index) in urgEventList" :key="event.id">
        <UrgentEventCard :event="event" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'HistoryUrgent',
  components: {
    SearchBarUrgent: () => import('@/components/History/SearchBarUrgent.vue'),
    UrgentEventCard: () => import('@/components/History/HistoryContent/EventBoard/UrgentEventCard.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      fullPage: false,
    }
  },
  computed: {
    ...mapState('historyUrgent', ['searchLoading', 'urgEventList']),
  },
  mounted() {
    this.updateHistoryEventModalType('urgent')
    this.updateUrgMode('chased')
  },
  beforeDestroy() {
    // 離開頁面清空搜尋資料
    this.resetHistoryUrgData()
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalType']),
    ...mapMutations('historyUrgent', ['updateUrgMode']),
    ...mapActions('historyUrgent', ['resetHistoryUrgData']),
    onCancel() {},
  },
}
</script>

<style lang="scss" scoped>
.history-urgent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.container-urgent {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px px2rem(16);
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
    background: #282942;
    position: relative;
  }
</style>